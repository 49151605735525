.iconHamburger{

  position: fixed;
  top:7%;
  left: 85%;
  transform: translate(-50%,-50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #0032a0;
  border: 1px #FFF solid;
  border-radius: 20px 0px 20px 0px;
  box-shadow: 3px 3px 3px #88888836;
  z-index: 999;
}
.hamburger{
  width: 30px;
  height: 2px;
  background-color: #FFF;
  position: absolute;
  top:50%;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%,-50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  transition: .5s;
}

.hamburger:before,
.hamburger:after
{
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  border-radius: 10px;
  background-color: #FFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  transition: .5s;
}
.hamburger:before
{
  top: -8px;
}
.hamburger:after
{
  top: 8px;
}
.iconHamburger.active .hamburger{
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}
.iconHamburger.active .hamburger:before{
  top: 0px;
  transform: rotate(45deg);
}
.iconHamburger.active .hamburger:after{
  top: 0px;
  transform: rotate(135deg);
}

/*  aqui comenzamos con el body del menu*/
#bodyMenu{
  position: fixed;
  width: 100%;
  height:100%;
  background-color: white;
  z-index: 800;
}
#bodyMenu > div{
  position: absolute;
  text-align: center;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  
}
#bodyMenu div{
 padding: 10px 0 10px 0;
  
}
.item-menu{

  font-size: .7rem;

}