@font-face {
  font-family: "Brandon text";
  src: url("../font/BrandonText-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brandon text";
  src: url("../font/BrandonText-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Brandon text";
  src: url("../font/BrandonText-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Brandon text", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 20px;
  }
  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sectionTitle {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 0 2rem;
  }
  .sectionTitleWhite {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 0 2rem;
    color: white;
  }

  .sectionSubTitle {
    margin: 0 auto;
    max-width: 721px;
  }
  .sectionSubTitleWhite {
    margin: 0 auto;
    max-width: 721px;
    color: white;
  }
  .header-text {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.1rem;
    min-width: 90%;
    max-width: 600px;
  }
  .subtitle-product {
    font-size: 1.4rem;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 0.5);
    max-width: 551px;
  }
}
@media screen and (min-width: 769px) {
  html {
    font-size: 20px;
  }
  h1 {
    font-size: 3.3rem;
    line-height: 2rem;
  }
  .sectionTitle {
    font-size: 2.3rem;
    line-height: 3rem;
    margin: 0 0 2rem;
  }
  .sectionTitleWhite {
    font-size: 2.3rem;
    line-height: 3rem;
    margin: 0 0 2rem;
    color: white;
  }
  .sectionSubTitle {
    max-width: 721px;
    margin: 0 auto 2rem;
  }
  .sectionSubTitleWhite {
    max-width: 721px;
    margin: 0 auto 2rem;
    color: white;
  }
  .header-text {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.1rem;
    max-width: 600px;
  }
  .subtitle-product {
    font-size: 2rem;
    line-height: 2.5rem;
    color: rgba(255, 255, 255, 0.5);
    max-width: 551px;
  }
}

h2 {
  font-size: 1.9rem;
}
h3 {
  font-size: 1.8rem;
}
h4 {
  font-size: 1.6rem;
}

button:focus,
.primary:focus,
.btn-primary.focus,
.btn-primary:focus {
  outline: 0;
  border: none;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

/************* Estilos para inputs type file **********************/

.button-wrapper {
  position: relative;
  width: 90%;
  text-align: center;
  margin-top: 10px;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #3cb4e5;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 20px;
}

#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

/*********** Estilos de lista page requerimientos *****************/
li {
  list-style-image: url("../images/list-arrow.svg");
  font-size: 17px;
}
.card-header .down {
  content: "";
  position: absolute;
  top: 25px;
  right: 3%;
  height: 20px;
  width: 20px;
  background-image: url("../images/chevron-down.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.card-header .down-active {
  content: "";
  position: absolute;
  top: 25px;
  right: 3%;
  height: 20px;
  width: 20px;
  background-image: url("../images/chevron-up.svg");
  background-position: center;
  background-repeat: no-repeat;
}
/*********** FIN  Estilos de lista page requerimientos *****************/

.container {
  padding-top: 100px;
  padding-bottom: 100px;
}
.container-fuild {
  margin-top: -40px;
  padding-bottom: 40px;
}
.row {
  padding-top: 30px;
}
.overflowHide {
  overflow: hidden;
}

/*############# Estilos del boton ##################*/

.btn-primary {
  width: 12rem;
  padding: 10px;
  border-radius: 4px;
  background: #ff6600;
  border-color: #ff6600;
  color: #fff;
  font-size: 0.8rem;
}

.btn-primary:hover {
  background: #f95428;
  border-color: #f95428;
}

/*############# Stilos del boton ##################*/
.btn-outline-primary {
  width: 12rem;
  padding: 10px;
  border-radius: 6px;
  background: transparent;
  border-color: #ff6600;
  color: #ff6600;
  font-size: 1rem;
}
.btn-outline-primary .ic {
  fill: #ff6600;
}
.btn-outline-primary:hover {
  background: #f95428;
  border-color: #f95428;
  color: #fff !important;
}
.btn-outline-primary:hover .ic {
  fill: #fff;
  transform: translate(10px, 0px);
}
.btn-outline-primary .ic {
  margin-left: 5px;
  transition: transform 0.3s ease-out;
  display: inline-block;
}

/* ############ secondary bottom ##################*/
.btn-outline-secondary {
  width: 12rem;
  padding: 10px;
  border-radius: 6px;
  background: transparent;
  border-color: #ff6600;
  color: #ff6600;
  font-size: 1rem;
}
.btn-outline-secondary .ic {
  fill: #ff6600;
}
.btn-outline-secondary:hover {
  background: #f95428;
  color: #fff;
  border-color: #f95428;
}
.btn-outline-secondary:hover .ic {
  fill: #fff;
  transform: translate(10px, 0px);
}
.btn-outline-secondary .ic {
  margin-left: 5px;
  transition: transform 0.3s ease-out;
  display: inline-block;
}
/************* Dark button ********************/

.dark-button {
  width: 12rem;
  text-align: center;
  padding: 10px;
  border-radius: 55px;
  background: transparent;
  border-color: #7a7a7a;
  color: #7a7a7a;
  font-size: 1rem;
}
.dark-button:hover {
  border-color: #000;
  background: #000;
  color: #fff !important;
}

.dark-button .ic {
  fill: #7a7a7a;
  margin-left: 5px;
  transition: transform 0.4s ease-out;
}
.dark-button:hover .ic {
  display: inline-block;
  fill: #fff;
  transform: translate(10px, 0px);
}
.ic {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  margin-top: -2px;
}
/*********** Button Product ***********/
.button-product {
  width: 12rem;
  padding: 10px;
  border-radius: 6px;
  background: transparent;
  border-color: #fff;
  color: #fff;
  font-size: 1rem;
}
.button-product .ic {
  fill: #fff;
}
.button-product:hover {
  background: #f95428;
  color: #fff;
  border-color: #f95428;
}
.button-product:hover .ic {
  fill: #fff;
  transform: translate(10px, 0px);
}
.button-product .ic {
  margin-left: 5px;
  transition: transform 0.3s ease-out;
  display: inline-block;
}

.product-detail-strong {
  font-size: 16px;
}

/********** style services **********************/
.services-text {
  max-width: 258px;
  margin: 10px auto;
}
/*** stytle product *************************/

/*############ Change style form #############*/
.hide-error {
  display: none;
}
.error-form {
  color: #ff2d2d;
  font-size: 15px;
  margin-left: 2px;
}
.form-control {
  background-color: #eeeeee !important;
  font-size: 15px;
  padding: 25px 20px;
  margin: 5px 10px 10px 0px;
  border-radius: 5px;
  border: none;
}
.form-control::-webkit-input-placeholder {
  color: #ababab;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #ababab;
} /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #ababab;
} /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #ababab;
} /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #ababab;
} /* Microsoft Edge */

.select-custom:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.select-custom {
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 5px;
  background-color: #eeeeee;
  border: none;
  color: #5d5d5d !important;
  padding-left: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
}

/*############ Change active  menu #################*/
.navbar-light .navbar-nav .nav-link:hover {
  display: inline-block;
  border-bottom: 2px solid #ff6600;
  padding-bottom: 1px;
}
.nav-link.active {
  display: inline-block;
  border-bottom: 2px solid #ff6600;
  padding-bottom: 1px;
}

/*######## estilos del preoloading ###########*/

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 800;
}
#loading > div {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*################## animacion card  ##########################*/
.cardPos {
  position: relative;
  max-width: 100%;
  height: 370px;
  text-align: left;
  color: #fff;
  padding: 10px 30px;
  margin: 20px auto;
  overflow: hidden;
  background: no-repeat center;
  background-size: cover;
  transition: all 0.4s;
  border-radius: 5px;
  cursor: pointer;
}

.cardPos::after,
.cardPos::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 450px;
  width: 100%;
  background-color: #000;
  transition: all 0.4s;
  z-index: 1;
}
.cardPos::before {
  opacity: 0.3;
}
.cardPos::after {
  opacity: 0;
}

.cardPos .card-title {
  position: absolute;
  bottom: 30px;
  line-height: 20px;
  transition: all 0.4s;
  z-index: 2;
}
.cardPos .card-content {
  position: absolute;
  max-height: 225px;
  min-height: 80px;
  max-width: 300px;
  top: 110%;
  font-size: 0.9rem;
  color: #fff;
  z-index: 2;
  transition: all 0.4s;
}
.cardPos .card-more {
  position: absolute;
  bottom: -250px;
  letter-spacing: 2px;
  font-size: 0.9rem;
  transition: all 0.4s;
  z-index: 2;
  cursor: pointer;
}
.cardPos:hover {
  box-shadow: 5px 5px 10px #00000061;
  transform: translate(0, -20px);
  border: 1px solid #ff6600;
}
.cardPos:hover::after {
  opacity: 0.7;
  background-color: #ff6600;
}
.cardPos:hover .card-title {
  bottom: 230px;
}
.cardPos:hover .card-content {
  top: 50%;
}
.cardPos:hover .card-more {
  bottom: 20px;
  color: #fff;
}
/******** animacion className='box-image' *********************/
.wraper-image {
  width: 100%;
  height: 500px;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}
.box-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #484848;
  opacity: 0;
  transition: all 0.4s;
  z-index: 1;
  cursor: pointer;
}
.wraper-image .content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  z-index: 2;
}
.wraper-image .content h1 {
  font-size: 1.8rem;
  margin: 0 0 2rem;
  color: white;
}
.wraper-image .content p {
  font-size: 0.9rem;
  margin: 20px auto;
  opacity: 0.8;
  max-width: 80%;
  color: #fff;
}
.wraper-image .content .ic {
  fill: #df5f23;
  width: 25px;
  transition: 0.5s;
}
.wraper-image .box-image {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
}

.wraper-image:hover .box-image {
  opacity: 0.4;
  transform: scale(1.2);
}
.wraper-image:hover .box-image::before {
  opacity: 0.5;
}
.wraper-image:hover .content .ic {
  transform: translateX(20px);
}

/* ######### footer style ############*/

.menu-footer {
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
}
.menu-footer a {
  color: #fff;
  text-decoration: none;
  font-weight: thin;
}
.menu-footer ul {
  list-style: none;
}
.social {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.social a {
  transition: all 0.4s;
}
.social a:hover {
  transform: translate(0, -10px);
}
.copyright {
  font-size: 16px;
}
.copyright a {
  text-decoration: none;
  color: #fff;
}
.svg-footer {
  margin: auto 12px;
}

/******* Animacion cafe *********/
.coffee {
  background-color: #e0e5ec;
  animation: inCoffe 2s ease 1 forwards;
}
.coffee-img {
  position: relative;
  display: inline-block;
  margin: 20px;
  background-color: transparent;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  /* box-shadow:  .5rem .5rem 1rem rgba(0,0,0,.1),
              -.5rem .5rem 1rem rgba(255,255,255,.5); */
}
.coffee-img i {
  color: #3cb4e5;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coffee .active {
  border: 3px solid #3cb4e5;
  /* border: 3px solid #E0E5EC;
  box-shadow:  inset 1rem 1rem 2rem rgba(0,0,0,.1),
               inset -1rem -1rem 2rem rgba(255,255,255,.5); */
}
.coffee .active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(224, 229, 236, 0.18);
  opacity: 1;
}
.coffee .active::after {
  content: "\2713";
  position: absolute;
  top: 30%;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 40px;
  color: #3cb4e5;
  opacity: 1;
}
@keyframes inCoffe {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/**************certifications-item ***************/
.certifications-item {
  background-color: #fafafa;
  width: 280px;
  height: 120px;
  margin: 10px 20px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  transition: all 0.5s ease;
}

/********** Animation notice ***************/
.notice {
  position: fixed;
  display: flex;
  top: 30%;
  right: -20px;
  min-height: 45px;
  z-index: 1000;
  color: #fff;
  align-items: center;
  padding-left: 10px;
  border-radius: 10px;
  border-radius: 20px 0;
  box-shadow: 4px 2px 7px #464545e6;
  opacity: 1;
  cursor: pointer;
  animation: inNotice 1s ease 1;
}
@keyframes inNotice {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(0);
  }
}
/* footer{

  position: fixed;
  bottom: 0;
  width: 100%;
  height: 300px;
  background-color: #FFF;
  z-index: -1;
} */

/* Certificaciones */
.img-hover:hover {
  background-color: #d6dbe0b3;
}

/* Estilos para los imagenes de bancos y afiliados */
.img-focus {
  height: 70%;
  width: 70%;
  padding: 5%;
}
.img-focus:hover {
  height: 71%;
  /* width: 71%; */
  border-radius: 15px;
  box-shadow: 5px 5px 40px rgba(39, 53, 68, 0.25);
}

.bank-animation::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border: 2px solid transparent;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}
.bank-animation:hover::before {
  height: 100%;
  width: 100%;
  border: 2px solid #d5d5d5;
  border-right: none;
  border-bottom: none;
  transition: height 0.5s linear, width 0.5s linear 0.5s;
}
.bank-animation::after {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border: 2px solid transparent;
  top: 0;
  right: 0;
  box-sizing: border-box;
}
.bank-animation:hover::after {
  height: 100%;
  width: 100%;
  border: 2px solid #d5d5d5;
  border-left: none;
  border-top: none;
  transition: height 0.5s linear, width 0.5s linear 0.5s;
}
/*######## estilos para sticky button #########*/

.back-to {
  font-size: 1.8rem;
  cursor: pointer;
  border-radius: 200px;
  padding: 10px;
}

.back-to:hover {
  background-color: #c1bdbb26;
}

/* Características de productos */
.hover-target:hover {
  background-color: #eceef1 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("../images/arrow-left-carousel.svg");
}
.carousel-control-next-icon {
  background-image: url("../images/arrow-right-carousel.svg");
}
/************** Change ul list ***********************/
ul {
  margin-top: 10px;
}
ul li {
  padding-left: 10px;
}

/********** back-top ***********************/
#back-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  bottom: 50px;
  left: 5px;
  z-index: 1000000;
  background-color: #fafafa;
  border: 1px solid #fafafa;
  background-image: url("../images/back-top.svg");
  background-position: center;
  background-size: cover;
  cursor: pointer;
  transition: 0.5s ease;
}
.back-show {
  visibility: visible !important ;
  opacity: 1 !important;
}
