/* menu principal*/
.menu-custom{
    transition: background-color .3s ease-out;
  }

  .menu-custom img{
    margin-left:30px;
    transition: .3s ease-out;

  }
/* cambiando el menu */

.menu-cta{
    border:1px solid #FF6600;
    border-radius: 20px;
    text-decoration: none;
    color: #FF6600 !important;
    padding: 7px 20px;
    font-size: .7rem;
    height: 35px;
  }
  .menu-cta:hover{
    text-decoration: none;
    color: #FFF !important;
    background-color: #FF6600;
  }
  
  .menu-cta-white{
    border:1px solid #FF6600;
    border-radius: 20px;
    text-decoration: none;
    color: #FF6600 !important;
    padding: 7px 20px;
    font-size: .7rem;
    height: 35px;
  }
  .menu-cta-white:hover{
    text-decoration: none;
    color: #FFF !important;
    border:1px solid #FF6600;
    background-color: #FF6600;
  }
  
  .menu-cta2{
    border:1px solid #FF6600;
    border-radius: 6px;
    text-decoration: none;
    color: #FFF !important;
    background-color: #FF6600;
    padding: 7px 20px;
    font-size: .7rem;
    height: 35px;
  }
  .menu-cta2:hover{
    text-decoration: none;
    background-color: #F95428;
    border:1px solid #F95428;


  }
/*############ Change active  menu #################*/
.navbar-light .navbar-nav .nav-link:hover {
    display:inline-block;
    border-bottom: 2px solid #FF6600;
    padding-bottom:7px;
  }
  .nav-link.active{
    display:inline-block;
    border-bottom: 2px solid #FF6600;
    padding-bottom:7px;
  }
  
.item-menu{
  font-size: 17px;
  margin:0 15px ;
}
/**************** css para el dropdown *************************/
.DropDownMenu{
    position: absolute;
    width:200px;
    top: 75px;
    right: 20px;
    background-color:#FFF;
    border:1px solid rgba(0, 0, 0, 0.25);
}
.DropDownItem{
    border: none;
    background-color: transparent;
    width: 100%;
    height: 40px;
    padding-left:10px;
    text-align:left;
    font-size:16px;
    color:rgba(0, 0, 0, 0.5);
}
.DropDownItem:hover{
    width: 100%;
    background-color:#0062cc;
    color:#FFFFFF;
}